@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import url('https://fonts.googleapis.com/css2?family=Ubuntu+Sans:ital,wght@0,100..800;1,100..800&display=swap');

$theme-colors: (
  "primary": #F68B1F,
  "secondary": #395E14,
  "danger": #ff4136,
  "warning": #E6C633,
  "success": #AEE647,
  "light": #f3f3f3,
  "white": #ffffff
);

@import "~bootstrap/scss/bootstrap";

body {
  font-family: "Ubuntu Sans", sans-serif!important;
  font-optical-sizing: auto!important;
  font-style: normal!important;
  background-color: #F68B1F;
}

.min-vh-80 {
  min-height: 70vh;
}

.bg-header {
  background-image: url("../public/img/banco-alimentare-header.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

@media screen and (max-width: 768px) {
  .bg-header {
    background-image: url("../public/img/banco-alimentare-header-mobile.png");
  }
}

.btn {
  color: white;
  font-weight: bolder;
  text-transform: uppercase;
  padding-left: 20px;
  padding-right: 20px;
}

.btn-primary {
  color: white!important;
}

select, input {
  font-weight: bolder!important;
}

.container, .container-fluid {
  background-color: white;
  min-height: 100vh;
}

.map-container {
  min-height: 400px;
}

.pt-title {
  padding-top: 250px;
}

@media screen and (max-width: 768px) {
  .pt-title {
    padding-top: 0px;
  }
}

.logo {
  max-height: 50px;
}

.trash-icon {
  cursor: pointer;
}

select:focus, input:focus {
  color: var(--bs-body-color);
  background-color: var(--bs-body-bg);
  border-color: #F68B1F!important;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgb(241, 184, 125) !important;
}